import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AccountState } from '../../appState/account/types';
import { AddressState } from '../../appState/address/types';
import { CampaignState } from '../../appState/campaign/types';
import { CartState } from '../../appState/cart/types';
import { CheckoutState, CreateVisitRequest } from '../../appState/checkout/types';
import { OnsiteState } from '../../appState/onsite/types';
import { PatientState } from '../../appState/patient/types';
import { VisitsState } from '../../appState/visit/types';
import { FEATURES, IMAGES, PRELOAD_IMAGES, ROUTES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { formatTimeslot } from '../../utils/date';
import { grabInitials } from '../../utils/strings';
import Button from '../core/Button';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import { RedirectWithSearch } from '../core/RedirectWithSearch';
import { NotificationsBox } from '../utils/NotificationsBox';
import { SelectableItem, SelectableItemStyles } from '../utils/SelectableItem';
import { Steps } from '../utils/Steps';
import styles from './BookVisit.module.scss';
import { ImageLoader } from './ImageLoader';

interface DispatchActions {
  actions: {
    saveVisit: (data: CreateVisitRequest) => void;
  };
}

export interface BookVisitProps extends DispatchActions, RouteComponentProps {
  cart: CartState;
  visitsState: VisitsState;
  addressState: AddressState;
  account: AccountState;
  patientList: PatientState;
  onsite: OnsiteState;
  campaign: CampaignState;
  checkout: CheckoutState;
}

interface BookVisitState {
  creating: boolean;
  createError?: string;
}

interface BookVisitCard {
  testId: string;
  label: string;
  path: string;
  value?: string;
  footer?: string;
  icon?: string;
  image?: string;
  initials?: string;
}

export class BookVisit extends React.Component<BookVisitProps, BookVisitState> {
  constructor(props: BookVisitProps) {
    super(props);
    this.state = {
      creating: false,
    };
  }

  public componentDidUpdate(prevProps: BookVisitProps) {
    const { checkout } = this.props;
    if (checkout.success && !checkout.isFetching && !checkout.error && checkout.data) {
      this.props.history.replace(ROUTES.bookThankYou.replace(':code', checkout.data.code));
    } else if (checkout.error && checkout.error !== prevProps.checkout.error) {
      this.setState({ createError: checkout.error, creating: false });
    }
  }
  public render(): JSX.Element {
    const { account, addressState, cart, history, onsite, visitsState } = this.props;

    const pdEnabled = account.data && account.data.features.indexOf(FEATURES.preferredDoctor) >= 0;
    if (!cart.who) {
      return <RedirectWithSearch to={ROUTES.bookPatient} />;
    } else if (!cart.where && !onsite.data && !onsite.isFetching) {
      return <RedirectWithSearch to={ROUTES.bookPatient} />;
    } else if (!cart.what && !cart.preferredDoctor && pdEnabled && !onsite.data) {
      return <RedirectWithSearch to={ROUTES.bookDoctor} />;
    } else if (!cart.what) {
      return <RedirectWithSearch to={ROUTES.bookReason} />;
    } else if (!cart.when) {
      return <RedirectWithSearch to={ROUTES.bookSchedule} />;
    }

    const onSiteData = onsite.data;

    let whereMessage: string | undefined;
    let whereValue: string | undefined;
    let whereFooter: string | undefined;
    if (onSiteData) {
      whereMessage = getString(ResourceKey.bookReviewWorkplaceMessage);
      if (onsite.data) {
        whereValue = onsite.data.partnerStreetAddress;
      }
    } else {
      whereMessage = getString(ResourceKey.bookReviewWhereMessage);
      if (cart.where) {
        whereValue = cart.where.streetAddress;
        whereFooter = `${cart.where.city} ${cart.where.state} ${cart.where.zipcode}`;
      }
    }

    const cards: BookVisitCard[] = [];

    cards.push({
      testId: 'select_patient',
      label: getString(ResourceKey.bookReviewWhoMessage),
      value: `${cart.who.firstName} ${cart.who.lastName}`,
      icon: IMAGES.patient,
      path: 'not_selectable',
    });
    cards.push({
      testId: 'select_location',
      label: whereMessage,
      value: whereValue,
      footer: whereFooter,
      icon: onSiteData ? IMAGES.bookVisitWorkplace : IMAGES.location,
      path: 'not_selectable',
    });
    if (cart.preferredDoctor) {
      const pd = cart.preferredDoctor;
      cards.push({
        testId: 'select_doctor',
        label: getString(ResourceKey.bookReviewDoctorMessage),
        value: `${pd.prefix} ${pd.firstName} ${pd.lastName}`,
        path: 'not_selectable',
        image: pd.avatarUrl,
        initials: grabInitials(`${pd.firstName} ${pd.lastName}`),
      });
    }
    cards.push({
      testId: 'select_reason',
      label: getString(ResourceKey.bookReviewWhatMessage),
      value: cart.what.title,
      footer: cart.what.info,
      icon: IMAGES.reason,
      path: 'not_selectable',
    });
    cards.push({
      testId: 'select_schedule',
      label: getString(ResourceKey.bookReviewWhenMessage),
      value: formatTimeslot(cart.when.startTime * 1000, (cart.when.startTime + cart.when.duration) * 1000, cart.when.timeZone),
      icon: IMAGES.calendar,
      path: 'not_selectable',
    });

    return (
      <Page showEmailBanner isLoading={account.isFetching || visitsState.isFetching || addressState.isFetching} title="Review" showBackButton>
        <ImageLoader images={PRELOAD_IMAGES.location} />
        <Steps history={history} currentStep="review" />
        <PageHeader title="Let's Review" subtitle={this.getPageTitle()} />
        <div className={styles.listContainer}>
          {cards.map((card) => {
            return (
              <SelectableItem
                key={card.testId}
                testId={card.testId}
                label={card.label}
                value={card.value}
                footer={card.footer}
                icon={card.icon}
                avatar={card.image}
                initials={card.initials}
                style={SelectableItemStyles.review}
                selected={false}
                tabIndex={-1}
              />
            );
          })}
        </div>

        <NotificationsBox type="error" content={this.state.createError} />

        <Button
          text={getString(ResourceKey.bookCompleteAppointment)}
          disabled={!this.canContinue()}
          loading={this.state.creating}
          testId="btn_pageBottom"
          className="btn-block"
          onClick={this.onContinue}
        />
      </Page>
    );
  }

  private getPageTitle(): string {
    if (this.props.onsite.publicCode) {
      return getString(ResourceKey.bookMainHeaderMessageWorkplace);
    } else {
      return getString(ResourceKey.bookMainHeaderMessageReturning);
    }
  }

  private canContinue = (): boolean => {
    return (!!this.props.cart.where || !!this.props.onsite.publicCode) && !!this.props.cart.who && !!this.props.cart.what && !!this.props.cart.when;
  };

  private onContinue = () => {
    this.setState({ creating: true, createError: undefined });
    const { cart, patientList, onsite } = this.props;
    const { who, what, when, where, notes, preferredDoctor } = cart;
    const patient = who?.id && patientList.data?.[who.id];
    if (!who || !what || !when || !patient) {
      return;
    }

    // TODO: All we should really need is the following to book a visit.
    // const data: CreateVisitRequest = {
    //   patientId: who.id,
    //   addressId: where ? where.addressId : undefined,
    //   serviceCode: what.serviceCode,
    //   symptoms: what.info,
    //   notes: notes || undefined,
    //   timeSlotId: when.id,
    //   selectedDoctorId: preferredDoctor?.providerId,
    //   attribution: this.props.campaign.attribution,
    //   source: 'patient_web'
    // };

    const visitCreateRequest: CreateVisitRequest = {
      partnerAddressId: onsite.data ? onsite.data.partnerAddressId : undefined,
      patientId: who.id,
      serviceCode: what.serviceCode,
      timeSlotId: when.id,
      promoCode: undefined,
      symptoms: what.info,
      notes: notes || undefined,
      paymentId: undefined,
      addressId: where ? where.addressId : undefined,
      establishment: '',
      address: where ? where.streetAddress : undefined,
      city: where ? where.city : undefined,
      unit: where ? where.unit : undefined,
      country: 'United States',
      zipcode: where ? where.zipcode : undefined,
      instructions: where ? where.entryInstructions : undefined,
      selectedDoctorId: preferredDoctor?.providerId,
      enrolledMedicare: patient.hasMedicare,
      delayedPayment: false,
      paymentToken: undefined,
      attribution: this.props.campaign.attribution,
    };

    // This will dispatch events...
    // Outcomes:
    //    1.  Error - show it on this page
    //    2.  Success - we navigate to /thank-you
    this.props.actions.saveVisit(visitCreateRequest);
  };
}

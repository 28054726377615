import * as React from 'react';
import { ReactSVG } from 'react-svg';
import { Service } from '../../appState/visitConfig/types';
import { IMAGES } from '../../services/constants';
import { getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { css } from '../../utils/css';
import { isChildVisit } from '../../utils/heal';
import Button from '../core/Button';
import TextArea from '../core/TextArea';
import { AccessibleElement } from '../utils/accessibility/AccessibleElement';
import { ToggleSwitch } from '../utils/ToggleSwitch';
import styles from './ReasonEdit.module.scss';

interface Props {
  allowLegalGuardianAcceptanceBypass: boolean;
  legalGuardianPresent: boolean;
  info?: string;
  reason: Service;
  onContinue: (info?: string) => void;
  openLegalGuardianBottomSheet: () => void;
  setLegalGuardianPresent: (legalGuardianPresent: boolean) => void;
}

interface State {
  info?: string;
}

export class ReasonEdit extends React.Component<Props, State> {
  static displayName = 'ReasonEdit';

  constructor(props: Props) {
    super(props);
    this.state = {
      info: props.info,
    };
  }

  public render(): JSX.Element {
    return (
      <div className={styles.container}>
        <TextArea
          id="note"
          name="note"
          label="Details"
          placeholder={this.props.reason.symptomsPlaceholder}
          required={this.props.reason.symptomsRequired}
          value={this.state.info}
          onChange={(e) => this.setState({ info: e.currentTarget.value })}
          testId="inp_symptoms"
        />

        {this.props.reason.code === 'TDAP_ADULT' && <p className={styles.emergency}>{getString(ResourceKey.TdapWarning)}</p>}
        {this.props.reason.code === 'ANPS_CHILD' && <p className={styles.emergency}>{getString(ResourceKey.ANPSWarning)}</p>}
        {isChildVisit(this.props.reason.code) && this.renderLegalGuardianConfirmation()}

        <Button
          text={getString(ResourceKey.genericButtonContinue)}
          className={styles.reasonButton}
          onClick={() => this.props.onContinue(this.state.info)}
          disabled={!this.canSubmit()}
          testId="btn_submitVisitReason"
        />

        {this.props.reason.symptomsRequired && <p className={styles.emergency}>{getString(ResourceKey.bookReasonEmergencyInfo)}</p>}
      </div>
    );
  }

  private renderLegalGuardianConfirmation(): JSX.Element | null {
    if (this.props.allowLegalGuardianAcceptanceBypass) {
      return null;
    }

    // Show legalGuardianSwitch
    return (
      <div className={styles.guardianContainer}>
        <div className={styles.label}>
          <label htmlFor="legalGuardian">
            {getString(ResourceKey.bookReasonCheckboxLegalGuardian)}
            <AccessibleElement className={styles.qmark} onClick={this.onMoreInfoClick} aria-label="More info (opens dialog)">
              <ReactSVG src={IMAGES.questionInfo} className={css(styles.icon, 'themeSVGFill')} />
            </AccessibleElement>
          </label>
        </div>
        <ToggleSwitch
          checkboxId="legalGuardian"
          on={this.props.legalGuardianPresent}
          onToggle={this.onLegalGuardianCheckboxChange}
          style={{ marginLeft: 10 }}
        />
      </div>
    );
  }

  private onMoreInfoClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.openLegalGuardianBottomSheet();
  };

  private onLegalGuardianCheckboxChange = () => {
    this.props.setLegalGuardianPresent(!this.props.legalGuardianPresent);
  };

  private canSubmit = (): boolean => {
    const { allowLegalGuardianAcceptanceBypass, legalGuardianPresent, reason } = this.props;

    if (isChildVisit(reason.code) && !allowLegalGuardianAcceptanceBypass && !legalGuardianPresent) {
      return false;
    }

    if (reason.symptomsRequired) {
      return !!this.state.info && this.state.info.length >= 3;
    }

    return true;
  };
}

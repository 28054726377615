import React from 'react';
import { css } from '../../utils/css';

export interface SelectOption {
  text: string;
  value: string | number;
}

interface SelectProps extends React.HTMLProps<HTMLSelectElement> {
  label?: string;
  error?: React.ReactNode;
  testId?: string;
  options: SelectOption[];
  defaultBlank?: boolean;
  accessory?: React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>; // Overrides incorrect React.FormEventHandler<HTMLSelectElement> inherited prop type
}

/**
 * Uses React.forwardRef to allow passing a ref prop down to the 'select' element (required for React Hook Form).
 *
 * Does not forward props.disabled to the 'select' element to allow it to still be included in form submissions.
 * Instead uses aria-disabled and CSS styling to indicate the field is disabled.
 */
const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ label, error, testId, options, defaultBlank = true, accessory, onChange, className, disabled, ...props }, ref) => {
    return (
      <div className={css('form-group', { invalid: !!error, required: !!props.required }, className)}>
        <div className="form-control-wrapper">
          <div className="form-label-wrapper">
            {label && (
              <label htmlFor={props.id} className="form-label">
                {label}
              </label>
            )}

            {accessory && <div className="form-control-accessory">{accessory}</div>}
          </div>

          <select
            ref={ref}
            className={css('form-control', { disabled: !!disabled })}
            name={props.id}
            data-tid={testId || `dd_${props.id}`}
            aria-invalid={!!error}
            aria-describedby={error ? `${props.id}_error` : undefined}
            aria-disabled={disabled}
            tabIndex={disabled ? -1 : 0}
            onChange={onChange}
            {...props}
          >
            {defaultBlank && <option />}

            {options.map((option, index) => (
              <option value={option.value} key={index}>
                {option.text}
              </option>
            ))}
          </select>
        </div>

        {error && (
          <div className="form-control-error" id={`${props.id}_error`} role="alert">
            {error}
          </div>
        )}
      </div>
    );
  }
);

Select.displayName = 'Select';
export default Select;

import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getAgreements } from '../../appState/agreements/actions';
import { AgreementsState } from '../../appState/agreements/types';
import { setPreferredDoctor, setReason } from '../../appState/cart/actions';
import { CartPreferredDoctor, CartState, Reason } from '../../appState/cart/types';
import { OnsiteState } from '../../appState/onsite/types';
import { RootState } from '../../appState/rootState';
import { fetchOnsiteServices, fetchServices, OnsiteServicesRequest, ServicesRequest, ServicesState, Status } from '../../appState/services';
import { Visit, VisitsState } from '../../appState/visit/types';
import { Service } from '../../appState/visitConfig/types';
import { ROUTES } from '../../services/constants';
import { createMarkup, getString } from '../../services/languages';
import { ResourceKey } from '../../services/languages/ResourceKey';
import { isChildVisit } from '../../utils/heal';
import { BottomSheet } from '../core/BottomSheet';
import { LegacyButton } from '../core/LegacyButton';
import Page from '../core/Page';
import { PageHeader } from '../core/PageHeader';
import { RedirectWithSearch } from '../core/RedirectWithSearch';
import { ReasonEdit } from '../ReasonEdit/ReasonEdit';
import { NotificationsBox } from '../utils/NotificationsBox';
import { SelectableItem, SelectableItemStyles } from '../utils/SelectableItem';
import { Steps } from '../utils/Steps';
import styles from './ServiceSelectionPage.module.scss';

interface Props extends RouteComponentProps {
  actions: {
    setReason: (patient: Reason) => void;
    setPreferredDoctor: (preferredDoctor?: CartPreferredDoctor) => void;
    getAgreements: (patientId: string, addressId?: string, partnerAddressId?: string, serviceCode?: string) => void;
    fetchServices: (servicesRequest: ServicesRequest) => void;
    fetchOnsiteServices: (servicesRequest: OnsiteServicesRequest) => void;
  };
  cart: CartState;
  services: ServicesState;
  onsite: OnsiteState;
  visitsState: VisitsState;
  agreements: AgreementsState;
}

export interface VisitTypeState {
  info?: string;
  reasonList?: Service[];
  reasonSelected?: Service;
  openFlyout?: boolean;
  openLegalGuardianBottomSheet: boolean;
  legalGuardianPresent: boolean;
}

export class ServiceSelectionPage extends React.Component<Props, VisitTypeState> {
  constructor(props: Props) {
    super(props);

    let reasonSelected: Service | undefined;
    let info: string | undefined;
    let reasonList: Service[] = [];

    if ((props.cart.where || props.onsite.publicCode) && props.cart.who && props.cart.what) {
      info = props.cart.what.info;
      const services = props.services.services;
      if (services) {
        const reasonValue = props.cart.what.serviceCode;
        reasonList = services;
        reasonSelected = services.find((item: Service) => item.code === reasonValue);
      }
    }

    this.state = {
      info,
      reasonSelected,
      reasonList,
      openLegalGuardianBottomSheet: false,
      legalGuardianPresent: false,
    };
  }

  public componentDidMount() {
    const {
      cart: { where, who, what },
      actions,
      onsite,
      services,
    } = this.props;

    if ((!where && !onsite.publicCode) || !who) {
      return;
    }

    const offerings = services.services;
    if (offerings.length === 0) {
      if (onsite.data?.partnerAddressId) {
        actions.fetchOnsiteServices({
          patientId: who.id,
          partnerAddressId: onsite.data.partnerAddressId,
        });
      } else if (where) {
        actions.fetchServices({ patientId: who.id, addressId: where.addressId });
      }
    } else {
      if (what && offerings) {
        const reasonValue = what.serviceCode;
        const reasonSelected = offerings.find((item: Service) => item.code === reasonValue);
        this.setState({ reasonList: offerings, reasonSelected });
      } else {
        this.setState({ reasonList: offerings });
      }
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const { status, services } = this.props.services;

    if (prevProps.services.status !== status && status === Status.succeeded) {
      if (this.props.cart.what && services) {
        const reasonValue = this.props.cart.what.serviceCode;
        const reasonSelected = services.find((item) => item.code === reasonValue);
        this.setState({ reasonList: services, reasonSelected });
      } else {
        this.setState({ reasonList: services });
      }
    }
  }

  public render(): JSX.Element {
    const { who, where } = this.props.cart;
    if (!who || !(where || this.props.onsite.publicCode)) {
      return <RedirectWithSearch to={ROUTES.bookPatient} />;
    }

    const { status, services } = this.props.services;
    const isLoading = status === Status.loading;
    if (services.length === 0 && !isLoading) {
      return this.renderError(getString(ResourceKey.bookReasonNoServices));
    }

    return (
      <Page title={getString(ResourceKey.bookReasonTitle)} showBackButton isLoading={isLoading}>
        <Steps history={this.props.history} currentStep="service" />
        <PageHeader title={getString(ResourceKey.bookReasonTitle)} subtitle={getString(ResourceKey.bookReasonHeader, [who.firstName])} />
        {this.renderReasons()}
        {this.renderReasonSheet()}
        {this.renderGuardianSheet()}
      </Page>
    );
  }

  public renderError(error: string): JSX.Element {
    return (
      <Page title={getString(ResourceKey.bookReasonTitle)} showBackButton isLoading={false}>
        <Steps history={this.props.history} currentStep={'service'} />
        <NotificationsBox type="info" content={error} />
      </Page>
    );
  }

  private getKey(props: Props): string {
    const { who, where } = props.cart;
    const { publicCode } = props.onsite;

    if (who && publicCode) {
      return `${who.id}_${publicCode}`;
    }

    if (who && where) {
      return `${who.id}_${where.zipcode}`;
    }

    if (who) {
      return `${who.id}`;
    }

    return 'unknownkey';
  }

  private renderReasons(): JSX.Element | null {
    let { reasonList, reasonSelected } = this.state;

    if (reasonList && reasonList?.length > 0) {
      return (
        <div className={styles.listContainer}>
          {reasonList.map((reason: Service) => (
            <ListItem
              key={reason.code}
              reason={reason}
              onSelect={this.onReasonSelect}
              selected={!!reasonSelected && reasonSelected.code === reason.code}
              testId={`list_${reason.code}`}
              preferredDoctor={this.props.cart.preferredDoctor}
              setPreferredDoctor={this.props.actions.setPreferredDoctor}
            />
          ))}
        </div>
      );
    }

    return (
      <div className={styles.highDemandContainer}>
        <div className={styles.textTitle}>{getString(ResourceKey.bookReasonHighDemandTitle)}</div>
        <div className={styles.textValue}>{getString(ResourceKey.bookReasonHighDemandMessage)}</div>
      </div>
    );
  }

  /*
  private renderCovid19Notice(): JSX.Element | null {
    const { covidSymptoms } = this.props.cart;
    const { reasonList } = this.state;

    const patientHasNoCovidSymptoms = covidSymptoms && covidSymptoms.length === 0;

    const reasonListContainsTelemedicine = reasonList?.length && reasonList.find((r) => r.code.indexOf('TELEMED') === 0);

    if (patientHasNoCovidSymptoms && reasonListContainsTelemedicine) {
      return <NotificationsBox type="info" title="Coronavirus Update" icon={IMAGES.info} content={getString(ResourceKey.bookReasonCovid19Message)} />;
    }
    return null;
  }
  */

  private renderGuardianSheet(): JSX.Element {
    return (
      <BottomSheet
        title={getString(ResourceKey.bottomSheetLegalGuardianTitle)}
        visible={this.state.openLegalGuardianBottomSheet}
        showCloseButton
        onClose={this.closeBottomSheetMinorDisclaimer}
      >
        <div className={styles.sheetContainer}>
          <div dangerouslySetInnerHTML={createMarkup(getString(ResourceKey.bottomSheetLegalGuardianText))} />
          <LegacyButton
            className={styles.sheetButton}
            text={getString(ResourceKey.genericButtonClose)}
            onClick={this.closeBottomSheetMinorDisclaimer}
            disabled={false}
            testId="btn_close"
          />
        </div>
      </BottomSheet>
    );
  }

  private closeBottomSheetMinorDisclaimer = () => {
    this.setState({ openLegalGuardianBottomSheet: false });
  };

  private openLegalGuardianBottomSheet = () => {
    this.setState({ openLegalGuardianBottomSheet: true });
  };

  private renderReasonSheet(): JSX.Element | null {
    if (!this.state.reasonSelected) {
      return null;
    }
    const content = (
      <ReasonEdit
        reason={this.state.reasonSelected}
        onContinue={this.onSubmitReasonEdit}
        info={this.state.info}
        openLegalGuardianBottomSheet={this.openLegalGuardianBottomSheet}
        setLegalGuardianPresent={this.setLegalGuardianPresent}
        legalGuardianPresent={this.state.legalGuardianPresent}
        allowLegalGuardianAcceptanceBypass={this.allowLegalGuardianAcceptanceBypass()}
      />
    );

    return (
      <BottomSheet title={this.state.reasonSelected.shortName} showCloseButton visible={this.state.openFlyout} onClose={this.closeNote}>
        {content}
      </BottomSheet>
    );
  }

  private allowLegalGuardianAcceptanceBypass(): boolean {
    const { visitsState, cart } = this.props;
    const patientId = cart.who?.id;
    const serviceCode = this.state.reasonSelected?.code;

    if (serviceCode === 'SICK_CHILD' && visitsState.data && patientId) {
      const completedVisits = visitsState.data.filter((visit: Visit) => {
        return visit.patient.id === patientId && visit.groupType === 'FINISHED_VISIT';
      });
      return completedVisits.length > 0;
    }
    return false;
  }

  private setLegalGuardianPresent = (legalGuardianPresent: boolean) => {
    this.setState({ legalGuardianPresent });
  };

  private closeNote = () => {
    this.setState({ openFlyout: false });
  };

  private onReasonSelect = (reason: any) => {
    let info: string | undefined;

    if (this.state.reasonSelected && this.state.reasonSelected.code === reason.code) {
      info = this.state.info;
    }

    this.setState({
      reasonSelected: reason,
      openFlyout: true,
      info,
    });
  };

  private onSubmitReasonEdit = (info?: string) => {
    this.setState({ openFlyout: false, info }, () => this.onSubmit());
  };

  private canSubmit = (): boolean => {
    if (this.state.reasonSelected) {
      if (isChildVisit(this.state.reasonSelected.code) && !this.allowLegalGuardianAcceptanceBypass() && !this.state.legalGuardianPresent) {
        return false;
      }

      if (this.state.reasonSelected.symptomsRequired) {
        return !!this.state.info && this.state.info.trim().length > 0;
      }
      return true;
    }
    return false;
  };

  private onSubmit = () => {
    const { cart, actions, onsite } = this.props;
    const { where, who } = cart;
    const { reasonSelected, info } = this.state;

    if (!this.canSubmit()) {
      return;
    }
    if (reasonSelected && who) {
      this.props.actions.setReason({
        serviceCode: reasonSelected.code,
        info: info,
        title: reasonSelected.shortName,
        allowInsurance: reasonSelected.allowInsurance,
        pricingDescription: reasonSelected.serviceDescription,
        id: reasonSelected.id,
        shortName: reasonSelected.shortName,
      });

      if (onsite.data) {
        actions.getAgreements(who.id, undefined, onsite.data.partnerAddressId, reasonSelected.code);
      } else if (where) {
        actions.getAgreements(who.id, where.addressId, undefined, reasonSelected.code);
      }

      this.props.history.push(ROUTES.bookSchedule);
    }
  };
}

interface ListItemProps {
  onSelect: (reason: any) => void;
  reason: Service;
  selected: boolean;
  testId: string;
  preferredDoctor: CartPreferredDoctor | null;
  setPreferredDoctor: (preferredDoctor?: CartPreferredDoctor) => void;
}

class ListItem extends React.Component<ListItemProps, object> {
  public render(): JSX.Element {
    const {
      reason: { iconUrl, shortName, serviceDescription },
      preferredDoctor,
    } = this.props;

    const doctorServices = preferredDoctor?.services;

    let onDemandOnly = false;
    if (doctorServices && doctorServices.indexOf(this.props.reason.id) === -1) {
      onDemandOnly = true;
    }

    let footerCopy = onDemandOnly ? 'Service available on-demand only. ' : '';
    if (this.props.reason.code.startsWith('FLU')) {
      footerCopy += 'You may be seen by a caring, qualified nurse';
    }
    return (
      <SelectableItem
        icon={iconUrl}
        role="button"
        tabIndex={0}
        style={SelectableItemStyles.service}
        testId={`service_${this.props.testId}`}
        label={shortName}
        ariaLabel={`Opens dialog to enter symptoms for the ${shortName} service`}
        value={serviceDescription}
        footer={footerCopy}
        selected={this.props.selected}
        onSelect={() => {
          if (onDemandOnly) {
            this.props.setPreferredDoctor(undefined);
          }
          this.onLinkClick();
        }}
        showCaret
      />
    );
  }

  private onLinkClick = () => {
    this.props.onSelect(this.props.reason);
  };
}

function mapStateToProps(state: RootState, ownProps: Props): Props {
  return {
    ...ownProps,
    cart: state.cart,
    services: state.services,
    onsite: state.onsite,
    visitsState: state.visitList,
    agreements: state.agreements,
  };
}

function mapDispatchToProps(dispatch: any, ownProps: Props): Props {
  return {
    ...ownProps,
    actions: bindActionCreators(
      {
        setReason,
        setPreferredDoctor,
        getAgreements,
        fetchServices,
        fetchOnsiteServices,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceSelectionPage);
